'use client';
import { ReactNode, Suspense } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AuthHandlerProvider } from '@/hooks/context/authContext';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || 'NOT FOUND';

  return (
    <Suspense fallback={<></>}>
      <AuthHandlerProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={recaptchaKey}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      </AuthHandlerProvider>
    </Suspense>
  );
};
