interface IProps {
  className?: string | undefined;
}

const BartursIcon = ({ className }: IProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 803.000000 149.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M0 744 l0 -745 503 3 c485 4 504 4 567 26 184 62 331 208 392 392 21
    63 22 83 26 568 l3 502 -745 0 -746 0 0 -746z"
        />
        <path
          d="M1720 755 l0 -735 90 0 90 0 0 92 0 91 32 -47 c99 -151 321 -199 503
    -110 107 53 201 182 236 324 20 81 17 298 -5 380 -40 145 -124 253 -243 312
    -64 31 -73 33 -178 33 -92 0 -118 -4 -157 -22 -64 -29 -113 -68 -154 -121
    l-34 -45 0 292 0 291 -90 0 -90 0 0 -735z m623 152 c88 -52 140 -141 159 -276
    30 -214 -59 -412 -203 -455 -223 -67 -399 101 -399 381 0 128 40 235 116 308
    59 58 110 75 205 72 61 -3 85 -9 122 -30z"
        />
        <path
          d="M4798 1228 l-3 -143 -77 -3 -78 -3 0 -74 0 -75 80 0 80 0 0 -364 c0
    -388 2 -409 51 -478 43 -59 193 -103 293 -84 l36 7 0 69 0 70 -50 0 c-28 0
    -66 7 -86 15 -62 26 -64 41 -64 425 l0 340 100 0 100 0 0 75 0 75 -100 0 -100
    0 0 145 0 145 -90 0 -89 0 -3 -142z"
        />
        <path
          d="M3195 1087 c-156 -40 -259 -142 -281 -275 l-7 -42 84 0 84 0 25 52
    c36 76 95 110 199 116 169 10 246 -54 258 -213 l6 -73 -64 -6 c-35 -3 -129
    -10 -209 -15 -167 -12 -240 -32 -314 -89 -76 -57 -101 -114 -101 -227 0 -78 4
    -97 27 -141 46 -88 124 -142 236 -164 146 -29 311 29 384 135 12 19 26 35 31
    35 4 0 7 -36 7 -80 l0 -80 85 0 85 0 0 403 c0 232 -4 417 -10 437 -26 93 -102
    170 -209 211 -69 27 -239 35 -316 16z m359 -654 c-2 -89 -28 -147 -90 -202
    -57 -50 -119 -71 -203 -70 -106 1 -167 34 -198 106 -36 87 17 178 120 208 51
    15 253 34 332 31 l40 -1 -1 -72z"
        />
        <path
          d="M4324 1075 c-61 -30 -84 -54 -118 -120 l-25 -50 -1 88 0 87 -90 0
    -90 0 0 -530 0 -530 90 0 90 0 0 348 c0 392 4 423 66 492 48 53 106 74 189 68
    l65 -4 0 88 0 88 -63 0 c-47 0 -76 -6 -113 -25z"
        />
        <path
          d="M6901 1087 c-50 -19 -108 -73 -136 -125 l-24 -47 -1 83 0 82 -90 0
    -90 0 0 -530 0 -530 90 0 90 0 0 358 c0 403 3 425 70 492 45 45 108 64 190 58
    l61 -4 -3 85 -3 86 -60 2 c-33 1 -75 -4 -94 -10z"
        />
        <path
          d="M7480 1084 c-146 -39 -237 -139 -248 -271 -6 -83 12 -143 60 -198 53
    -60 135 -97 306 -140 79 -19 159 -42 179 -51 68 -33 94 -124 54 -190 -42 -69
    -190 -103 -304 -70 -68 20 -121 68 -138 126 l-12 40 -90 0 -89 0 7 -37 c10
    -60 49 -135 91 -177 119 -119 373 -150 549 -68 128 60 185 143 185 269 0 85
    -17 133 -63 181 -49 52 -117 81 -286 123 -85 22 -171 47 -191 57 -107 56 -104
    189 5 246 48 25 144 32 203 16 60 -16 120 -72 135 -127 l11 -43 84 0 85 0 -7
    47 c-13 93 -81 190 -165 233 -97 49 -250 64 -361 34z"
        />
        <path
          d="M5412 678 c3 -393 4 -404 26 -460 49 -123 158 -201 298 -214 130 -12
    246 28 322 110 23 25 45 46 47 46 3 0 5 -31 5 -70 l0 -70 85 0 85 0 0 530 0
    530 -85 0 -85 0 0 -320 c0 -395 -9 -449 -92 -526 -59 -56 -94 -69 -188 -69
    -97 0 -152 22 -190 75 -46 65 -50 100 -50 482 l0 358 -91 0 -90 0 3 -402z"
        />
      </g>
    </svg>
  );
};

export default BartursIcon;
