export interface IErrorResponseBody {
  Type: string;
  Title: string;
  Status: number;
  traceId: string;
}

export interface ILoginResponse {
  Token: string;
  User: IUser;
}
export type TLoginResponse = ILoginResponse | IErrorResponseBody;

export interface IRegisterCredentials {
  Subject: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Message: string;
  Locale: string;
}

export interface ICredentials {
  Email: string;
  Password: string;
}

export interface IChangePassword {
  Password: string;
  NewPassword: string;
}

export interface IUser {
  Id: string;
  FullName: string;
  Email: string;
  Role?: number; // should be UserRoleEnum
  OrganizationType?: EnumOrganizationType;
  IsManager: boolean;
}

export interface IUserDTO {
  FullName: string;
  Email: string;
  OrganizationType?: EnumOrganizationType;
  IsManager: boolean;
}

export enum EnumOrganizationType {
  Company = 1,
  Educational_Institution = 2,
  Industry_Association = 3,
  Organs_Of_Public_Power = 4,
}

export enum OrganizationTypeEnumPT {
  Empresa = 1,
  Instituição_de_Ensino = 2,
  Associação_Industrial = 3,
  Órgãos_Do_Poder_Público = 4,
}
