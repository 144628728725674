'use client';
import NextTopLoader from 'nextjs-toploader';
import { usePathname } from '@/navigation';

import { useTextColorClass } from '@/lib/navigation';

export default function PageLoader() {
  const pathname = usePathname() as string;
  const color = useTextColorClass(pathname, false, true);

  return (
    <NextTopLoader
      // color="#FF8B0780" // observaturs color
      color={color || '#CC2136'}
      initialPosition={0.08}
      height={3}
      crawl={true}
      easing="ease"
      speed={200}
      zIndex={1600}
    />
  );
}
